import * as React from 'react';
import { ReactElement } from 'react';
import { isEmpty, transformAddress } from '@docavenue/core';
import { Alert, Button } from '@mui/material';
import { Address } from '@maiia/model/generated/model/api-patient/api-patient';
import Autocomplete from '../Autocomplete';
import AddressItem from '../AddressItem/AddressItem';
import { useTranslation } from '@/src/i18n';
import { DEFAULT_ADDRESSES } from '@/src/constants';
import useAddresses from '@/src/hooks/useAddresses/useAddresses';

type Props = {
  initialInputValue?: any;
  inputProps?: Object;
  inputClassName?: string;
  inputContainerClassName?: string;
  leftComponent?: ReactElement;
  onChange: (selectedItem: any) => void;
  allowClear?: boolean;
  onClear?: Function;
  setOpenDialogAddress?: () => void;
  isCustomizableAddress?: boolean;
  inputValue?: string;
  hasCustomAddress?: boolean;
  dropdownClassName?: string;
};

const AddressField = ({
  initialInputValue,
  inputProps,
  inputClassName,
  inputContainerClassName,
  leftComponent,
  onChange,
  setOpenDialogAddress,
  isCustomizableAddress,
  hasCustomAddress = false,
  dropdownClassName,
  ...props
}: Props) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [addresses, setAddresses] = React.useState<Address[]>(
    DEFAULT_ADDRESSES,
  );
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(-1);
  const { t } = useTranslation();

  const onSearch = React.useCallback(async value => {
    onChange(undefined); // Trigger onChange empty to reset value
    setSelectedItemIndex(-1);
    if (!value) {
      setAddresses(DEFAULT_ADDRESSES);
      return;
    }

    setSearchTerm(value);
  }, []);

  const { isFetching, isError } = useAddresses(searchTerm, {
    onSuccess: dataSuccess => {
      const _addresses = dataSuccess?.map(transformAddress);
      if (_addresses) {
        setAddresses(_addresses);
      }
    },
    onError: () => {
      setAddresses(DEFAULT_ADDRESSES);
    },
  });

  return (
    <div
      className="input-dropdown address with-icons"
      data-cy="search-address-input"
    >
      <Autocomplete
        bottomElement={
          isCustomizableAddress && (
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={setOpenDialogAddress}
            >
              {hasCustomAddress
                ? t('edit-custom-address')
                : t('add-custom-address')}
            </Button>
          )
        }
        onSearch={onSearch}
        onChange={onChange}
        suggestions={
          isEmpty(addresses) && !isFetching ? DEFAULT_ADDRESSES : addresses
        }
        placeholder={t('placeholder-where')}
        initialInputValue={initialInputValue}
        selectedItemIndex={selectedItemIndex}
        renderItem={itemProps => (
          <AddressItem {...itemProps} searchTerm={searchTerm} />
        )}
        inputProps={{ ...inputProps, type: 'search' }}
        inputClassName={inputClassName}
        inputContainerClassName={inputContainerClassName}
        leftComponent={leftComponent}
        isLoading={isFetching}
        renderRightIcon={({ rightIcon }) => rightIcon}
        showQuickAddress
        dropdownClassName={dropdownClassName}
        topElement={
          isError && (
            <Alert severity="error" icon={false}>
              {t('error:search_unavailable')}
            </Alert>
          )
        }
        {...props}
      />
    </div>
  );
};

export default AddressField;
