import React from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { addressesActions, serialActions } from '@docavenue/core';

import { Button, IconButton, Separator } from '@kiiwi/ui';
import Alert from '../../atoms/Alert/Alert';
import SuggestionInput from '../Formik/SuggestionInput';
import AddressInputField from '../Formik/AddressInputField';
import { useTranslation } from '@/src/i18n';
import { analyticsEvent } from '@/src/analytic_tag_manager';
import { APP_ROUTES } from '@/src/constants';
import FontAwesomeIcon from '@/components/atoms/FontawesomeIcon/FontawesomeIcon';
import useResponsive from '@/src/hooks/useResponsive';

type FormValues = {
  suggestion: any;
  address: any;
};

type Props = {
  focusInput?: boolean;
  allowClear?: boolean;
};

// TODO(nampnq):

function errorsHasRoot(errors: any): errors is { root: string } {
  if (typeof errors?.root === 'string') {
    return true;
  }
  return false;
}

/*
  Refactor this
  Current this is mixing of global state in redux, and component state
  refactor Autocomplete have props items, item, and pass it prop from parent component
*/
const SearchBar: React.FC<Props> = ({ focusInput, allowClear }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const { isMobile } = useResponsive();
  const suggestion = useSelector((state: any) => state?.suggestions?.item);
  const address = useSelector((state: any) => state?.addresses?.item);
  const [addressKey, setAddressKey] = React.useState(address?.id);
  const [suggestionKey, setSuggestionKey] = React.useState(suggestion?.id);
  const formikRef = React.useRef<FormikProps<FormValues>>(null);

  const initialValues = React.useMemo(() => ({ suggestion, address }), []);
  const validateFn = React.useCallback(values => {
    const errors: any = {};
    if (!values.suggestion && !values.address) {
      errors.root = 'search_bar_missing_required_info';
      return errors;
    }
    return errors;
  }, []);

  const onSubmit = React.useCallback(
    async ({ suggestion: suggestionValue, address: addressValue }) => {
      const _suggestion = suggestionValue ?? {
        shortName: 'medecin-generaliste',
      };
      const _address = addressValue ?? {
        type: 'municipality',
        zipCode: '75001',
        shortName: 'PARIS',
        label: 'Paris (75000)',
        fullAddress: 'Paris',
      };
      if (_suggestion.url) {
        router.push(_suggestion.url);
        return;
      }
      const { shortName: specialityShortName } = _suggestion;
      const {
        type,
        label,
        longitude,
        latitude,
        zipCode,
        shortName: localityShortName,
        analyticsType,
      } = _address;

      let query: any = {
        speciality: specialityShortName,
        locality: label,
      };

      const items = [{ ..._address }];
      dispatch(
        serialActions.serial([
          () => addressesActions.setItems(items),
          state => addressesActions.setItem(state.addresses.items[0]),
        ]),
      );

      if (['municipality', 'region', 'department'].includes(type)) {
        query = {
          ...query,
          locality: `${zipCode}-${localityShortName}`,
        };
      } else {
        query = {
          ...query,
          longitude,
          latitude,
        };
      }

      analyticsEvent({
        category: 'SearchForm',
        action: 'SubmitForm',
        label: `${specialityShortName}_${localityShortName}`,
      });
      analyticsEvent({
        category: 'SearchForm',
        action: 'ClickGeoLoc',
        label: analyticsType || type,
      });

      // Pop out page, long, lat, getAllVaccinationPlaces query from previous query
      const {
        page,
        longitude: _long,
        latitude: _lat,
        getAllVaccinationPlaces: _getAllVaccinationPlaces,
        ...routerQuery
      } = router.query;

      router.push({
        pathname: APP_ROUTES.SPECIALITY.LOCALITY,
        query: {
          ...routerQuery,
          ...query,
        },
      });
    },
    [router],
  );

  React.useEffect(() => {
    if (formikRef.current && address) {
      formikRef.current.setFieldValue('address', address);
      setAddressKey(address?.id);
    }
  }, [address]);

  React.useEffect(() => {
    if (formikRef.current && suggestion) {
      formikRef.current.setFieldValue('suggestion', suggestion);
      setSuggestionKey(suggestion?.id);
    }
  }, [suggestion]);

  // Check if current page is homepage,
  // needed to reset 'searchBar' inputs on homepage only
  const isHomepage = router.pathname === APP_ROUTES.HOME;

  return (
    <Formik
      initialValues={
        isHomepage
          ? { suggestion: undefined, address: undefined }
          : initialValues
      }
      validate={validateFn}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ errors }) => (
        <Form className="flex h-fit w-full justify-center">
          {errorsHasRoot(errors) && (
            <Alert dataCy="form-error">{t(`error:${errors.root}`)}</Alert>
          )}
          <div
            className={`searchbar rounded-full border border-global-neutral bg-white p-0.5 text-left ${
              isHomepage ? 'w-full max-w-[800px]' : 'w-full max-w-[700px]'
            }`}
          >
            <Field
              key={suggestionKey}
              name="suggestion"
              focusInput={focusInput}
              placeholder={t('home_search_placeholder')}
              component={SuggestionInput}
              allowClear={allowClear}
              className="with-icon"
            />
            <div>
              <Separator orientation="vertical" className="py-2.5" />
            </div>
            <Field
              key={addressKey}
              name="address"
              component={AddressInputField}
              allowClear={allowClear}
              className="with-icons"
              dropdownClassName={isHomepage ? 'mt-7' : 'mt-5'}
            />
            {isMobile || !isHomepage ? (
              <IconButton
                className="rounded-full bg-macro-enable text-white hover:bg-macro-hover"
                size="sm"
                type="submit"
                data-cy="btn-search"
                aria-label={t('accessibility_search_button')}
              >
                <FontAwesomeIcon name="magnifying-glass:regular" />
              </IconButton>
            ) : (
              <Button variant="macro" type="submit" size="lg">
                Rechercher
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SearchBar;
