import React from 'react';
import { Typography } from '@mui/material';
import HighlightText from '../../atoms/HighlightText/HighlightText';

type AddressItemProps = {
  rawData: any;
  searchTerm: string;
};

export const formatAddressLine = rawData => {
  const { type, label, postcode } = rawData.properties;

  if (type === 'municipality' && postcode) {
    return `${label} ${postcode}`;
  }

  return label;
};

const AddressItem = ({ rawData, searchTerm }: AddressItemProps) => (
  <Typography my={0.5}>
    <HighlightText text={formatAddressLine(rawData)} term={searchTerm} />
  </Typography>
);

export default AddressItem;
