import React from 'react';
import clsx from 'clsx';

interface Props {
  className?: string;
  iconComponent?: React.ReactNode;
  severity?: 'success' | 'info' | 'warning';
  variant?: 'filled';
  dataCy?: string;
  children?: React.ReactNode;
  id?: string;
}

const Alert = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      children,
      iconComponent,
      severity,
      variant,
      dataCy,
      id,
      ...props
    },
    ref,
  ) => {
    const _className = clsx(
      className,
      'alert',
      severity && `is-${severity}`,
      variant && `is-${variant}`,
    );

    return (
      <div className={_className} id={id} data-cy={dataCy} {...props} ref={ref}>
        {iconComponent && <>{iconComponent}</>}
        <span>{children}</span>
      </div>
    );
  },
);

Alert.displayName = 'Alert';

export default Alert;
